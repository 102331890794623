<template>
    <div>
        <b-card>
            <h2>Add New Group</h2>
            <b-form-group
                label="Group Name"
            >
                <b-input
                    v-model="group_name"
                ></b-input>
            </b-form-group>
            <div class="text-center">
                <b-button
                    @click="addGroup()"
                    :disabled="adding"
                >
                    <b-spinner
                        v-if="adding"
                        class="mr-2"
                        small
                    ></b-spinner>
                    Add Group
                </b-button>
            </div>
        </b-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            group_name: "",
            adding: false
        };
    },
    methods: {
        addGroup() {
            this.adding = true;
            this.$api.post(
                "/api/admin/groups/add",
                {
                    group_name: this.group_name
                }
            ).then(() => {
                this.adding = false;
                this.$router.push({ name: 'list_groups' });
            })
        }
    }
}
</script>